body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.box {
  box-shadow: -4px 10px 35px -1px rgba(0, 0, 0, 0.75);
  min-height: 20rem;
  margin: 30px;
  padding: 20px;
  border-radius: 5px;
}

h1 {
  margin-left: 20px;
}

button {
  float: right;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background: transparent;
}
